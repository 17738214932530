@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.carousel-container {
  width: 100%;
  position: relative;
}

.carousel-track {
  display: flex;
  animation: scroll 10s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
